import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createI18n } from "vue-i18n";
import axios from "axios";

require("./assets/css/style.css");

import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const i18n = createI18n({
  locale: "en",
  messages: {
    ko: {
      // Common Text
      CommonWallet: "지갑",
      CommonLockup: "락업",
      CommonPresale: "프리세일",
      CommonStaking: "스테이킹",
      CommonDividend: "배당",
      CommonAmount: "수량",
      CommonWithdrawAmount: "출금 수량",
      CommonWithdrawableAmount: "출금 예상 수량",
      CommonRewardAmount: "보상 수량",
      CommonStartDate: "시작일",
      CommonEndDate: "종료일",
      CommonNoList: "목록이 없습니다.",
      CommonList: "대",

      // Wallet Text
      CommonMyWallet: "내 지갑",
      CommonMyAddress: "내 계정",
      CommonAddress: "주소",
      CommonCopyAddress: "주소 복사",
      CommonShareLink: "링크 공유",
      CommonSend: "보내기",
      CommonMore: "더보기",

      // Lockup Text
      CommonNumber: "번호",
      CommonWithdrawCount: "출금 횟수",
      CommonStatus: "상태",
      CommonLocked: "락업",
      CommonRelease: "해제",
      CommonStop: "정지",

      // Presale Text
      CommonBuyToken: "토큰 구매",
      CommonMin: "최소",
      CommonMax: "최대",

      // Staking Text
      CommonMyStaking: "내 스테이킹",
      CommonType: "타입",
      CommonProgress: "진행",
      CommonCompleted: "완료",
      CommonPaused: "일시정지",
      CommonSlots: "현재 / 한도",
      CommonDuration: "기간",
      CommonDailyPayoutQuantity: "일일 지급 수량",
      CommonStakingQuantity: "스테이킹 수량",
      CommonTotalReward: "총 보상",
      CommonDays: "일",
      CommonTotalAmount: "총액",
      CommonReferrerTotalAmount: "추천인 총액",
      CommonWithdraw: "출금",

      // Dividend Text
      CommonMyDividend: "내 배당",
      CommonDepositedAmkTokens: "예치된 AMK 토큰",
      CommonExpectedProfit: "예상 수익",
      CommonJoinDividend: "배당 참여",
      CommonDividendProject: "배당 프로젝트",
      CommonPcmCooling: "PCM 축냉 에어컨",
      CommonExpectedDividend: "예상 배당",
      CommonDepositYour: "계약에 AMK 토큰을 예치하고 배당 날짜까지 보유하면 배당 대상이 됩니다. 참여하면 보상을 받을 수 있습니다.",
    
      // Common Set Text
      CommonSetLockup: "락업 설정",
      CommonSetPresale: "프리세일 설정",
      CommonSetStaking: "스테이킹 설정",
      CommonReferrerRegistration: "추천인 등록",
      CommonReferrer: "추천인",
      CommonConfirm: "확인",
      CommonCancel: "취소",

      // Staking Information
      stakingInformation: "스테이킹 정보",
      stakingInfoTitle01: "수수료 공제",
      stakingInfoText01: "인출 시 5%의 수수료가 공제됩니다.",
      stakingInfoTitle02: "스테이킹 제한",
      stakingInfoText02: "활성 스테이킹은 하나만 허용됩니다.",
      stakingInfoTitle03: "회원 요구 사항",
      stakingInfoText03: "등록 후에만 스테이킹이 가능합니다.",
      stakingInfoTitle04: "보상 구조",
      stakingInfoText04: "추천 보상은 보상의 양을 늘리는 것이 아닌 스테이킹 전체 보상을 당겨서 받는 개념입니다.",
      stakingInfoTitle05: "추천 보상",
      stakingInfoText05: "나를 추천한 사람이 스테이킹하면 다음과 같은 보상을 받을 수 있습니다.",
      stakingInfoTitle06: "1차 추천",
      stakingInfoText06: "스테이킹 금액의 7%",
      stakingInfoTitle07: "2차 추천",
      stakingInfoText07: "스테이킹 금액의 5%",
      stakingInfoTitle08: "3차 추천",
      stakingInfoText08: "스테이킹 금액의 3%",
      stakingInfoTitle09: "4차 추천",
      stakingInfoText09: "스테이킹 금액의 2%",
      stakingInfoTitle10: "5차 추천",
      stakingInfoText10: "스테이킹 금액의 1%",
      stakingInfoTitle11: "6차 추천",
      stakingInfoText11: "스테이킹 금액의 1%",
      stakingInfoTitle12: "7차 추천",
      stakingInfoText12: "스테이킹 금액의 1%",

      // Page State Wallet
      walletWrapTextFront: "당신의",
      walletWrapTextBack: "로 더 많은 가치를 발견하세요",
      walletWrapTextPoint: "AMK",

      // Page State Presale
      presaleWrapTextFrontStart: "프리세일이",
      presaleWrapTextPointStart: "종료되었습니다",
      presaleWrapTextFrontLive: "프리세일이",
      presaleWrapTextPointLive: "종료되었습니다",

      // Alert Success Message
      alertSuccessChangeAdmin: "관리자가 변경되었습니다.",
      alertSuccessAddLockup: "락업이 추가되었습니다.",
      alertSuccessSetFeeRecipient: "수수료 수취인이 변경되었습니다.",
      alertSuccessSetFeeRate: "수수료 비율이 변경되었습니다.",
      alertSuccessSetPresale: "프리세일 설정이 완료되었습니다.",
      alertSuccessSetStakingLimit: "스테이킹 제한이 변경되었습니다.",
      alertSuccessSendAmount: "금액이 전송되었습니다.",
      alertSuccessBuyToken: "토큰이 구매되었습니다.",
      alertSuccessWithdrawFunds: "자금이 인출되었습니다.",
      alertSuccessWithdrawToken: "토큰이 인출되었습니다.",
      alertSuccessEndPresale: "프리세일이 종료되었습니다.",
      alertSuccessStartStaking: "스테이킹이 시작되었습니다.",
      alertSuccessLockupStart: "락업이 시작되었습니다.",
      alertSuccessLockupRelease: "락업이 해제되었습니다.",
      alertSuccessLockupStop: "락업이 중지되었습니다.",
      alertSuccessChangeStakingStatus: "스테이킹 상태가 변경되었습니다.",
      alertSuccessCopyAddress: "주소가 복사되었습니다.",
      alertSuccessCopyLink: "링크가 복사되었습니다.",
      alertSuccessLockupData: "락업 데이터가 변경되었습니다.",

      // Alert Error Message
      alertErrorConnectWallet: "지갑을 연결해주세요.",
      alertErrorUserReject: "사용자가 요청을 거부했습니다.",
      alertErrorRequestFailed: "요청이 실패했습니다. 나중에 다시 시도해주세요.",
      alertErrorFeeRate: "수수료 비율은 0 에서 100 사이로만 입력할 수 있습니다.",
      alertErrorChainId: "체인 변경에 실패했습니다. 나중에 다시 시도해주세요.",
      alertErrorEnterAddress: "주소를 입력해주세요.",
      alertErrorEnterAmount: "수량을 입력해주세요.",
      alertErrorEnterQuantity: "수량을 입력해주세요.",
      alertErrorInvalidAddress: "주소가 유효하지 않습니다.",
      alertErrorInvalidAmount: "수량이 유효하지 않습니다.",
      alertErrorInvalidDate: "날짜가 유효하지 않습니다.",
      alertErrorTransactionFailed: "거래가 실패했습니다. 나중에 다시 시도해주세요.",
      alertErrorMaximumStaking: "총 3개의 스테이킹만 허용되며, 활성 스테이킹은 하나만 허용됩니다.",
      alertErrorSetPresaleValue: "설정할 값을 입력해주세요.",
      alertErrorSetPresaleMinPurchase01: "최소 수량이 현재 입력된 최대 수량을 초과합니다.",
      alertErrorSetPresaleMinPurchase02: "최소 수량이 현재 설정된 최대 수량을 초과합니다.",
      alertErrorSetPresaleMaxPurchase01: "최대 수량이 현재 입력된 최소 수량보다 작습니다.",
      alertErrorSetPresaleMaxPurchase02: "최대 수량이 현재 설정된 최소 수량보다 작습니다.",
      alertErrorSetPresalePrice: "가격을 입력해주세요.",
      alertErrorNotMember: "입력한 추천인 주소는 회원이 아닙니다.",
      alertErrorSetStakingType: "타입을 선택해주세요.",
      alertErrorSetStakingTotalLimit: "수량을 입력해주세요.",
      alertErrorSignUp: "회원가입 중 오류가 발생했습니다. 나중에 다시 시도해주세요",
      alertErrorInsufficientBalance: "잔액이 부족합니다.",
      alertErrorMinimumQuantity: "입력한 수량이 최소 수량보다 작습니다.",
      alertErrorMaximumQuantity: "입력한 수량이 최대 수량보다 큽니다.",
      alertErrorTimeOut: "요청 시간이 초과되었습니다. 나중에 다시 시도해주세요.",
      alertErrorNotStakingDate: "스테이킹 시작 시간(GMT) : 2024-04-20 AM 12:00:00",
      alertErrorUnknown: "알 수 없는 오류가 발생했습니다. 나중에 다시 시도해주세요.",
      alertErrorAlreadyStaking: "이미 스테이킹 중입니다.",
      alertErrorUserNotFound: "사용자를 찾을 수 없습니다.",
      alertErrorNotAdmin: "관리자가 아닙니다.",
      alertErrorNotDividend: '현재 배당이 진행되고 있지않습니다.',
      alertErrorEnterIndex: "번호를 입력해주세요.",
      alertErrorEndStaking: "해당 스테이킹의 진행 개수가 최대입니다.",

      // Staking Alert Error Message
      alertErrorClaimOnce: "UTC 자정 이후 하루에 한 번만 출금할 수 있습니다.",
      // alertErrorOnlyActiveClaim: "보상을 청구하려면 스테이킹이 활성화되어야합니다.",
      alertErrorOnlyActiveClaim: "출금 가능한 수량이 없습니다.",
      alertErrorSearchAfterStaking: "스테이킹 후 검색 가능합니다.",

    },
    en: {
      // Common Text
      CommonWallet: "Wallet",
      CommonLockup: "Lockup",
      CommonPresale: "Presale",
      CommonStaking: "Staking",
      CommonDividend: "Dividend",
      CommonAmount: "Amount",
      CommonWithdrawAmount: "Withdraw Amount",
      CommonWithdrawableAmount: "Estimated withdrawal amount",
      CommonRewardAmount: "Reward Amount",
      CommonStartDate: "Start Date",
      CommonEndDate: "End Date",
      CommonNoList: "No List.",
      CommonList: "Generation",

      // Wallet Text
      CommonMyWallet: "My Wallet",
      CommonMyAddress: "My Address",
      CommonAddress: "Address",
      CommonCopyAddress: "Copy Address",
      CommonShareLink: "Share Link",
      CommonSend: "Send",
      CommonMore: "More",

      // Lockup Text
      CommonNumber: "Number",
      CommonWithdrawCount: "Withdraw Count",
      CommonStatus: "Status",
      CommonLocked: "Locked",
      CommonRelease: "Released",
      CommonStop: "Stop",

      // Presale Text
      CommonBuyToken: "Buy Token",
      CommonMin: "Min",
      CommonMax: "Max",

      // Staking Text
      CommonMyStaking: "My Staking",
      CommonType: "Type",
      CommonProgress: "Progress",
      CommonCompleted: "Completed",
      CommonPaused: "Paused",
      CommonSlots: "Current / Limit",
      CommonDuration: "Duration",
      CommonDailyPayoutQuantity: "Daily Payout Quantity",
      CommonStakingQuantity: "Staking Quantity",
      CommonTotalReward: "Total Reward",
      CommonDays: "Days",
      CommonTotalAmount: "Total Amount",
      CommonReferrerTotalAmount: "Referrer Total Amount",
      CommonWithdraw: "Withdraw",

      // Dividend Text
      CommonMyDividend: "My Dividend",
      CommonDepositedAmkTokens: "Deposited AMK Tokens",
      CommonExpectedProfit: "Expected Profit",
      CommonJoinDividend: "Join Dividend",
      CommonDividendProject: "Dividend Project",
      CommonPcmCooling: "PCM Air Conditional",
      CommonExpectedDividend: "Expected Dividend",
      CommonDepositYour: "Deposit your AMK tokens into the contract and hold them until the dividend date to qualify for dividends. Get rewarded for participating.",

      // Common Set Text
      CommonSetLockup: "Set Lockup",
      CommonSetPresale: "Set Presale",
      CommonSetStaking: "Set Staking",
      CommonReferrerRegistration: "Referrer Registration",
      CommonReferrer: "Referrer",
      CommonConfirm: "Confirm",
      CommonCancel: "Cancel",

      // Staking Information
      stakingInformation: "Staking Information",
      stakingInfoTitle01: "Fee Disclosure",
      stakingInfoText01: "5% fee will be deducted upon claiming.",
      stakingInfoTitle02: "Staking Limit",
      stakingInfoText02: "Only one active staking is allowed. After termination, up to 3 additional stakings can be added.",
      stakingInfoTitle03: "Membership Requirement",
      stakingInfoText03: "Staking is only possible after registration.",
      stakingInfoTitle04: "Reward Structure",
      stakingInfoText04: "Rewards will be distributed daily over 360 days. The concept of referral rewards is about claiming rewards early rather than increasing the amount of rewards.",
      stakingInfoTitle05: "Referral Rewards",
      stakingInfoText05: "If the person who referred you stakes, you can receive the following rewards.",
      stakingInfoTitle06: "1st Referral",
      stakingInfoText06: "7% of their staking amount",
      stakingInfoTitle07: "2nd Referral",
      stakingInfoText07: "5% of their staking amount",
      stakingInfoTitle08: "3rd Referral",
      stakingInfoText08: "3% of their staking amount",
      stakingInfoTitle09: "4th Referral",
      stakingInfoText09: "2% of their staking amount",
      stakingInfoTitle10: "5th Referral",
      stakingInfoText10: "1% of their staking amount",
      stakingInfoTitle11: "6th Referral",
      stakingInfoText11: "1% of their staking amount",
      stakingInfoTitle12: "7th Referral",
      stakingInfoText12: "1% of their staking amount",

      // Page State Wallet
      walletWrapTextFront: "Discover More Value with Your",
      walletWrapTextBack: "Here",
      walletWrapTextPoint: "AMK",

      // Page State Presale
      presaleWrapTextFrontStart: "Presale has",
      presaleWrapTextPointStart: "Finished",
      presaleWrapTextFrontLive: "Presale has",
      presaleWrapTextPointLive: "Finished",

      // Alert Success Message
      alertSuccessChangeAdmin: "The administrator has been changed.",
      alertSuccessAddLockup: "Lockup has been added.",
      alertSuccessSetFeeRecipient: "Fee recipient has been changed.",
      alertSuccessSetFeeRate: "Fee rate has been changed.",
      alertSuccessSetPresale: "Presale settings have been completed.",
      alertSuccessSetStakingLimit: "Staking limit has been changed.",
      alertSuccessSendAmount: "Amount has been sent.",
      alertSuccessBuyToken: "Token has been purchased.",
      alertSuccessWithdrawFunds: "Funds have been withdrawn.",
      alertSuccessWithdrawToken: "Token has been withdrawn.",
      alertSuccessEndPresale: "Presale has ended.",
      alertSuccessStartStaking: "Staking has started.",
      alertSuccessLockupStart: "Lockup has been started.",
      alertSuccessLockupRelease: "Lockup has been released.",
      alertSuccessLockupStop: "Lockup has been stopped.",
      alertSuccessChangeStakingStatus: "Staking status has been changed.",
      alertSuccessCopyAddress: "Address has been copied.",
      alertSuccessCopyLink: "Link has been copied.",
      alertSuccessLockupData: "Lockup data has been changed.",

      // Alert Error Message
      alertErrorConnectWallet: "Please connect your wallet.",
      alertErrorUserReject: "User rejected the request.",
      alertErrorRequestFailed: "The request has failed. Please try again later.",
      alertErrorFeeRate: "Fee rate can only be entered between 0 and 100.",
      alertErrorChainId: "The chain modification failed. Please try again later.",
      alertErrorEnterAddress: "Please enter the address.",
      alertErrorEnterAmount: "Please enter the amount.",
      alertErrorEnterQuantity: "Please enter the quantity.",
      alertErrorInvalidAddress: "The address is invalid.",
      alertErrorInvalidAmount: "The amount is invalid.",
      alertErrorInvalidDate: "The date is invalid.",
      alertErrorTransactionFailed: "Transaction failed. Please try again later.",
      alertErrorMaximumStaking: "Maximum of 3 stakes in total allowed, only one active stake allowed.",
      alertErrorSetPresaleValue: "Please enter the value to set.",
      alertErrorSetPresaleMinPurchase01: "The minimum quantity exceeds the current maximum quantity entered.",
      alertErrorSetPresaleMinPurchase02: "The minimum quantity exceeds the currently set maximum quantity.",
      alertErrorSetPresaleMaxPurchase01: "The maximum quantity entered is less than the currently set minimum quantity.",
      alertErrorSetPresaleMaxPurchase02: "The maximum quantity entered is less than the minimum quantity.",
      alertErrorSetPresalePrice: "Please enter the price.",
      alertErrorNotMember: "The entered referral address is not a member.",
      alertErrorSetStakingType: "Please select the type.",
      alertErrorSetStakingTotalLimit: "Please enter the total limit.",
      alertErrorSignUp: "An error occurred during the registration process. Please try again later",
      alertErrorInsufficientBalance: "Insufficient balance.",
      alertErrorMinimumQuantity: "The entered quantity is less than the minimum quantity.",
      alertErrorMaximumQuantity: "The entered quantity is greater than the maximum quantity.",
      alertErrorTimeOut: "Request timed out. Please try again later.",
      alertErrorNotStakingDate: "Staking Start Time(GMT) : 2024-04-20 AM 12:00:00",
      alertErrorUnknown: "An unknown error occurred. Please try again later.",
      alertErrorAlreadyStaking: "You are already staking.",
      alertErrorUserNotFound: "User not found.",
      alertErrorNotAdmin: "You are not an administrator.",
      alertErrorNotDividend: 'Dividend is not currently in progress.',
      alertErrorEnterIndex: "Please enter the number.",
      alertErrorEndStaking: "The progress count of the current staking has reached the maximum value.",

      // Staking Alert Error Message
      alertErrorClaimOnce: "Claim can only be made once a day after midnight UTC",
      // alertErrorOnlyActiveClaim: "Stake must be active to claim rewards",
      alertErrorOnlyActiveClaim: "There is no amount to withdraw.",
      alertErrorSearchAfterStaking: "Search is available after staking",
    },
    zh: {
      // Common Text
      CommonWallet: "钱包",
      CommonLockup: "锁定",
      CommonPresale: "预售",
      CommonStaking: "质押",
      CommonDividend: "分红",
      CommonAmount: "数量",
      CommonWithdrawAmount: "提取数量",
      CommonWithdrawableAmount: "预计提款金额",
      CommonRewardAmount: "奖励数量",
      CommonStartDate: "开始日期",
      CommonEndDate: "结束日期",
      CommonNoList: "没有列表。",
      CommonList: "代",

      // Wallet Text
      CommonMyWallet: "我的钱包",
      CommonMyAddress: "我的地址",
      CommonAddress: "地址",
      CommonCopyAddress: "复制地址",
      CommonShareLink: "分享链接",
      CommonSend: "发送",
      CommonMore: "更多",
      
      // Lockup Text
      CommonNumber: "编号",
      CommonWithdrawCount: "提取次数",
      CommonStatus: "状态",
      CommonLocked: "锁定",
      CommonRelease: "释放",
      CommonStop: "停止",
      
      // Presale Text
      CommonBuyToken: "购买代币",
      CommonMin: "最小",
      CommonMax: "最大",

      // Staking Text
      CommonMyStaking: "我的质押",
      CommonType: "类型",
      CommonProgress: "进度",
      CommonCompleted: "完成",
      CommonPaused: "暂停",
      CommonSlots: "当前 / 限制",
      CommonDuration: "持续时间",
      CommonDailyPayoutQuantity: "每日支付数量",
      CommonStakingQuantity: "质押数量",
      CommonTotalReward: "总奖励",
      CommonDays: "天",
      CommonTotalAmount: "总金额",
      CommonReferrerTotalAmount: "推荐人总金额",
      CommonWithdraw: "提取",

      // Dividend Text
      CommonMyDividend: "我的分红",
      CommonDepositedAmkTokens: "存入的AMK代币",
      CommonExpectedProfit: "预期利润",
      CommonJoinDividend: "加入分红",
      CommonDividendProject: "分红项目",
      CommonPcmCooling: "PCM冷却空调",  
      CommonExpectedDividend: "预期分红",
      CommonDepositYour: "将您的AMK代币存入合同并保留到分红日期以符合分红资格。 参与即可获得奖励。",

      // Common Set Text
      CommonSetLockup: "设置锁定",
      CommonSetPresale: "设置预售",
      CommonSetStaking: "设置质押",
      CommonReferrerRegistration: "推荐人注册",
      CommonReferrer: "推荐人",
      CommonConfirm: "确认",
      CommonCancel: "取消",

      // Staking Information
      stakingInformation: "质押信息",
      stakingInfoTitle01: "费用披露",
      stakingInfoText01: "索取时将扣除5%的费用。",
      stakingInfoTitle02: "质押限制",
      stakingInfoText02: "只允许一个活跃的质押。 解除后，最多可以添加3个额外的质押。",
      stakingInfoTitle03: "会员要求",
      stakingInfoText03: "注册后才能进行质押。",
      stakingInfoTitle04: "奖励结构",
      stakingInfoText04: "奖励将在360天内每天分配。 推荐奖励的概念是提前索取奖励而不是增加奖励数量。",
      stakingInfoTitle05: "推荐奖励",
      stakingInfoText05: "如果推荐您的人质押，您可以获得以下奖励。",
      stakingInfoTitle06: "第一推荐",
      stakingInfoText06: "其质押金额的7%",
      stakingInfoTitle07: "第二推荐",
      stakingInfoText07: "其质押金额的5%",
      stakingInfoTitle08: "第三推荐",
      stakingInfoText08: "其质押金额的3%",
      stakingInfoTitle09: "第四推荐",
      stakingInfoText09: "其质押金额的2%",
      stakingInfoTitle10: "第五推荐",
      stakingInfoText10: "其质押金额的1%",
      stakingInfoTitle11: "第六推荐",
      stakingInfoText11: "其质押金额的1%",
      stakingInfoTitle12: "第七推荐",
      stakingInfoText12: "其质押金额的1%",

      // Page State Wallet
      walletWrapTextFront: "发现更多价值与您的",
      walletWrapTextBack: "这里",
      walletWrapTextPoint: "AMK",

      // Page State Presale
      presaleWrapTextFrontStart: "预售已",
      presaleWrapTextPointStart: "结束",
      presaleWrapTextFrontLive: "预售已",
      presaleWrapTextPointLive: "结束",

      // Alert Success Message
      alertSuccessChangeAdmin: "管理员已更改。",
      alertSuccessAddLockup: "锁定已添加。",
      alertSuccessSetFeeRecipient: "费用接收人已更改。",
      alertSuccessSetFeeRate: "费率已更改。",
      alertSuccessSetPresale: "预售设置已完成。",
      alertSuccessSetStakingLimit: "质押限制已更改。",
      alertSuccessSendAmount: "金额已发送。",
      alertSuccessBuyToken: "代币已购买。",
      alertSuccessWithdrawFunds: "资金已提取。",
      alertSuccessWithdrawToken: "代币已提取。",
      alertSuccessEndPresale: "预售已结束。",
      alertSuccessStartStaking: "质押已开始。",
      alertSuccessLockupStart: "锁定已开始。",
      alertSuccessLockupRelease: "锁定已释放。",
      alertSuccessLockupStop: "锁定已停止。",
      alertSuccessChangeStakingStatus: "质押状态已更改。",
      alertSuccessCopyAddress: "地址已复制。",
      alertSuccessCopyLink: "链接已复制。",
      alertSuccessLockupData: "锁定数据已更改。",

      // Alert Error Message
      alertErrorConnectWallet: "请连接您的钱包。",
      alertErrorUserReject: "用户拒绝了请求。",
      alertErrorRequestFailed: "请求失败。请稍后再试。",
      alertErrorFeeRate: "费率只能在0到100之间输入。",
      alertErrorChainId: "链修改失败。请稍后再试。",
      alertErrorEnterAddress: "请输入地址。",
      alertErrorEnterAmount: "请输入金额。",
      alertErrorEnterQuantity: "请输入数量。",
      alertErrorInvalidAddress: "地址无效。",
      alertErrorInvalidAmount: "金额无效。",
      alertErrorInvalidDate: "日期无效。",
      alertErrorTransactionFailed: "交易失败。请稍后再试。",
      alertErrorMaximumStaking: "总共只允许3个质押，只允许一个活跃的质押。",
      alertErrorSetPresaleValue: "请输入要设置的值。",
      alertErrorSetPresaleMinPurchase01: "最小数量超过了当前输入的最大数量。",
      alertErrorSetPresaleMinPurchase02: "最小数量超过了当前设置的最大数量。",
      alertErrorSetPresaleMaxPurchase01: "输入的最大数量小于当前设置的最小数量。",
      alertErrorSetPresaleMaxPurchase02: "输入的最大数量小于最小数量。",
      alertErrorSetPresalePrice: "请输入价格。",
      alertErrorNotMember: "输入的推荐人地址不是会员。",
      alertErrorSetStakingType: "请选择类型。",
      alertErrorSetStakingTotalLimit: "请输入总限制。",
      alertErrorSignUp: "注册过程中发生错误。请稍后再试",
      alertErrorInsufficientBalance: "余额不足。",
      alertErrorMinimumQuantity: "输入的数量小于最小数量。",
      alertErrorMaximumQuantity: "输入的数量大于最大数量。",
      alertErrorTimeOut: "请求超时。请稍后再试。",
      alertErrorNotStakingDate: "质押开始时间(GMT)：2024-04-20 AM 12:00:00",
      alertErrorUnknown: "发生未知错误。请稍后再试。",
      alertErrorAlreadyStaking: "您已经在质押。",
      alertErrorUserNotFound: "找不到用户。",
      alertErrorNotAdmin: "您不是管理员。",
      alertErrorNotDividend: '分红当前未进行中。',
      alertErrorEnterIndex: "请输入编号。",
      alertErrorEndStaking: "当前质押的进度计数已达到最大值。",

      // Staking Alert Error Message
      alertErrorClaimOnce: "UTC午夜后一天只能提取一次。",
      // alertErrorOnlyActiveClaim: "要索取奖励，质押必须处于活动状态。",
      alertErrorOnlyActiveClaim: "没有可提取的金额。",
      alertErrorSearchAfterStaking: "质押后可搜索。",
    },
    ja: {
      // Common Text
      CommonWallet: "ウォレット",
      CommonLockup: "ロックアップ",
      CommonPresale: "プリセール",
      CommonStaking: "ステーキング",
      CommonDividend: "配当",
      CommonAmount: "数量",
      CommonWithdrawAmount: "引き出し金額",
      CommonWithdrawableAmount: "出金予定金額",
      CommonRewardAmount: "報酬金額",
      CommonStartDate: "開始日",
      CommonEndDate: "終了日",
      CommonNoList: "リストがありません。",
      CommonList: "世代",

      // Wallet Text
      CommonMyWallet: "私のウォレット",
      CommonMyAddress: "私のアドレス",
      CommonAddress: "アドレス",
      CommonCopyAddress: "アドレスをコピー",
      CommonShareLink: "リンクを共有",
      CommonSend: "送信",
      CommonMore: "もっと",

      // Lockup Text
      CommonNumber: "番号",
      CommonWithdrawCount: "引き出し回数",
      CommonStatus: "状態",
      CommonLocked: "ロック",
      CommonRelease: "解放",
      CommonStop: "停止",

      // Presale Text
      CommonBuyToken: "トークン購入",
      CommonMin: "最小",
      CommonMax: "最大",

      // Staking Text
      CommonMyStaking: "私のステーキング",
      CommonType: "タイプ",
      CommonProgress: "進捗",
      CommonCompleted: "完了",
      CommonPaused: "一時停止",
      CommonSlots: "現在 / 制限",
      CommonDuration: "期間",
      CommonDailyPayoutQuantity: "毎日の支払い数量",
      CommonStakingQuantity: "ステーキング数量",
      CommonTotalReward: "総報酬",
      CommonDays: "日",
      CommonTotalAmount: "合計金額",
      CommonReferrerTotalAmount: "紹介者合計金額",
      CommonWithdraw: "引き出し",

      // Dividend Text
      CommonMyDividend: "私の配当",
      CommonDepositedAmkTokens: "預金されたAMKトークン",
      CommonExpectedProfit: "予想利益",
      CommonJoinDividend: "配当に参加",
      CommonDividendProject: "配当プロジェクト",
      CommonPcmCooling: "PCM冷却空調",
      CommonExpectedDividend: "予想配当",
      CommonDepositYour: "AMKトークンを契約に預け入れ、配当日まで保持すると配当の対象になります。 参加して報酬を受け取ります。",

      // Common Set Text
      CommonSetLockup: "ロックアップ設定",
      CommonSetPresale: "プリセール設定",
      CommonSetStaking: "ステーキング設定",
      CommonReferrerRegistration: "紹介者登録",
      CommonReferrer: "紹介者",
      CommonConfirm: "確認",
      CommonCancel: "キャンセル",

      // Staking Information
      stakingInformation: "ステーキング情報",
      stakingInfoTitle01: "手数料開示",
      stakingInfoText01: "請求時に5％の手数料が差し引かれます。",
      stakingInfoTitle02: "ステーキング制限",
      stakingInfoText02: "アクティブなステーキングは1つのみ許可されます。 解除後、最大3つの追加ステーキングが追加できます。",
      stakingInfoTitle03: "会員要件",
      stakingInfoText03: "登録後にステーキングが可能です。",
      stakingInfoTitle04: "報酬構造",
      stakingInfoText04: "報酬は360日間毎日分配されます。 紹介報酬の概念は報酬の量を増やすのではなく、早期に報酬を請求することです。",
      stakingInfoTitle05: "紹介報酬",
      stakingInfoText05: "あなたを紹介した人がステーキングすると、以下の報酬を受け取ることができます。",
      stakingInfoTitle06: "1次紹介",
      stakingInfoText06: "ステーキング金額の7％",
      stakingInfoTitle07: "2次紹介",
      stakingInfoText07: "ステーキング金額の5％",
      stakingInfoTitle08: "3次紹介",
      stakingInfoText08: "ステーキング金額の3％",
      stakingInfoTitle09: "4次紹介",
      stakingInfoText09: "ステーキング金額の2％",
      stakingInfoTitle10: "5次紹介",
      stakingInfoText10: "ステーキング金額の1％",
      stakingInfoTitle11: "6次紹介",
      stakingInfoText11: "ステーキング金額の1％",
      stakingInfoTitle12: "7次紹介",
      stakingInfoText12: "ステーキング金額の1％",

      // Page State Wallet
      walletWrapTextFront: "あなたの",
      walletWrapTextBack: "でより多くの価値を発見する",
      walletWrapTextPoint: "AMK",

      // Page State Presale
      presaleWrapTextFrontStart: "プリセールが",
      presaleWrapTextPointStart: "終了しました",
      presaleWrapTextFrontLive: "プリセールが",
      presaleWrapTextPointLive: "終了しました",

      // Alert Success Message
      alertSuccessChangeAdmin: "管理者が変更されました。",
      alertSuccessAddLockup: "ロックアップが追加されました。",
      alertSuccessSetFeeRecipient: "手数料受取人が変更されました。",
      alertSuccessSetFeeRate: "手数料率が変更されました。",
      alertSuccessSetPresale: "プリセール設定が完了しました。",
      alertSuccessSetStakingLimit: "ステーキング制限が変更されました。",
      alertSuccessSendAmount: "金額が送信されました。",
      alertSuccessBuyToken: "トークンが購入されました。",
      alertSuccessWithdrawFunds: "資金が引き出されました。",
      alertSuccessWithdrawToken: "トークンが引き出されました。",
      alertSuccessEndPresale: "プリセールが終了しました。",
      alertSuccessStartStaking: "ステーキングが開始されました。",
      alertSuccessLockupStart: "ロックアップが開始されました。",
      alertSuccessLockupRelease: "ロックアップが解除されました。",
      alertSuccessLockupStop: "ロックアップが停止されました。",
      alertSuccessChangeStakingStatus: "ステーキングステータスが変更されました。",
      alertSuccessCopyAddress: "アドレスがコピーされました。",
      alertSuccessCopyLink: "リンクがコピーされました。",
      alertSuccessLockupData: "ロックアップデータが変更されました。",

      // Alert Error Message
      alertErrorConnectWallet: "ウォレットに接続してください。",
      alertErrorUserReject: "ユーザーがリクエストを拒否しました。",
      alertErrorRequestFailed: "リクエストに失敗しました。後でもう一度お試しください。",
      alertErrorFeeRate: "手数料率は0から100の間でのみ入力できます。",
      alertErrorChainId: "チェーンの変更に失敗しました。後でもう一度お試しください。",
      alertErrorEnterAddress: "アドレスを入力してください。",
      alertErrorEnterAmount: "金額を入力してください。",
      alertErrorEnterQuantity: "数量を入力してください。",
      alertErrorInvalidAddress: "アドレスが無効です。",
      alertErrorInvalidAmount: "金額が無効です。",
      alertErrorInvalidDate: "日付が無効です。",
      alertErrorTransactionFailed: "トランザクションに失敗しました。後でもう一度お試しください。",
      alertErrorMaximumStaking: "合計3つのステーキングのみが許可され、アクティブなステーキングは1つのみ許可されます。",
      alertErrorSetPresaleValue: "設定する値を入力してください。",
      alertErrorSetPresaleMinPurchase01: "最小数量が現在入力された最大数量を超えています。",
      alertErrorSetPresaleMinPurchase02: "最小数量が現在設定された最大数量を超えています。",
      alertErrorSetPresaleMaxPurchase01: "最大数量が現在入力された最小数量よりも小さいです。",
      alertErrorSetPresaleMaxPurchase02: "最大数量が最小数量よりも小さいです。",
      alertErrorSetPresalePrice: "価格を入力してください。",
      alertErrorNotMember: "入力した紹介者アドレスは会員ではありません。",
      alertErrorSetStakingType: "タイプを選択してください。",
      alertErrorSetStakingTotalLimit: "合計制限を入力してください。",
      alertErrorSignUp: "登録中にエラーが発生しました。後でもう一度お試しください",
      alertErrorInsufficientBalance: "残高が不足しています。",
      alertErrorMinimumQuantity: "入力した数量が最小数量よりも少ないです。",
      alertErrorMaximumQuantity: "入力した数量が最大数量よりも多いです。",
      alertErrorTimeOut: "リクエストがタイムアウトしました。後でもう一度お試しください。",
      alertErrorNotStakingDate: "ステーキング開始時間(GMT)： 2024-04-20 AM 12:00:00",
      alertErrorUnknown: "不明なエラーが発生しました。後でもう一度お試しください。",
      alertErrorAlreadyStaking: "すでにステーキング中です。",
      alertErrorUserNotFound: "ユーザーが見つかりません。",
      alertErrorNotAdmin: "あなたは管理者ではありません。",
      alertErrorNotDividend: '現在、配当は進行中ではありません。',
      alertErrorEnterIndex: "番号を入力してください。",
      alertErrorEndStaking: "現在のステーキングの進行回数が最大値に達しました。",

      // Staking Alert Error Message
      alertErrorClaimOnce: "UTC午前0時以降、1日に1回のみ請求できます。",
      // alertErrorOnlyActiveClaim: "報酬を請求するには、ステーキングがアクティブである必要があります。",
      alertErrorOnlyActiveClaim: "引き出す金額がありません。",
      alertErrorSearchAfterStaking: "ステーキング後に検索可能です。",
    },
  },
});

const axiosInstance = axios.create({
  // 기본 헤더 설정
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: "https://api.anemoi.finance",
});

const app = createApp(App);
app.config.globalProperties.$axios = axiosInstance;

app.use(pinia).use(router).use(i18n).mount("#app");
